<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",
  created() {
    this.handleLogout();
    this.$router.push("/");
  },
  methods: {
    ...mapActions("account", ["logout"]),
    handleLogout() {
      this.logout();
    },
  },
};
</script>
